@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600&display=swap');
@import url(./assets/fonts/d-din-fonts.css);

:root {
    --white      : #ffffff;
    --black      : #212121;
    --black-light: #313131;
    --gray       : #707070;
}

html {
    font-family            : 'D-DIN';
    font-size              : 62.5%;
    /* Safari/Chromium and Firefox equivalents that turn on font-smoothing on macOS */
    -webkit-font-smoothing : antialiased;
    -moz-osx-font-smoothing: grayscale;
    scroll-behavior: smooth;
}

body {
    font-weight: 400;
    font-size  : 1.6rem;
    line-height: 2.4rem;
    color      : var(--black);
}

.container {
    width : 100%;
    margin: 0 auto;
    padding: 0 2.4rem;
}

.sub_title {
    font-weight: 400;
    font-size  : 3.0rem;
    line-height: 3.3rem;
    letter-spacing: -0.01em;
    text-transform: capitalize;
}

@media (max-width: 1199.98px) {
    .sub_title {
        font-size  : 2.4rem;
        line-height: 3.2rem;
    }
}
@media (orientation: portrait) {
    .sub_title {
        font-size  : 2rem;
        line-height: 2.2rem;
    }
}


/* for min width */
@media screen and (min-width: 1600px) {
    .container {
        max-width: 1560px;
        padding: 0;
    }
}