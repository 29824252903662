header {
    padding   : 4.8rem 0;
    background: #F4F4F4;
}

header .container {
    display        : flex;
    justify-content: space-between;
    align-items    : center;
}

header .brand_name,
header .link {
    display: inline-block;
    color  : var(--black);
}

header .brand_name {
    font-weight   : 700;
    font-size     : 3.0rem;
    line-height   : 2.5rem;
    letter-spacing: -0.01em;
    text-transform: uppercase;
}

header .link {
    font-size     : 1.7rem;
    line-height   : 2.5rem;
    letter-spacing: -0.01em;
    text-transform: capitalize;
}

@media (orientation: portrait) {
    header {
        padding: 2.4rem 0;
    }

    header .brand_name {
        font-size     : 2rem;
    }
}