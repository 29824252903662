.story_slider_section {
    padding: 14rem 0 17rem;
}

.story_slider .swiper-slide {
    background: var(--white);
}

.story_slider .swiper-pagination {
    left  : 39%;
    bottom: 2rem;
}

.story_slide {
    display              : grid;
    grid-template-columns: 1fr 2fr;
    column-gap           : 11rem;
}

.story_slide img {
    width                     : 100%;
    max-width                 : 43.9rem;
    border-top-left-radius    : 3rem;
    border-bottom-right-radius: 3rem;
}

.story_slide h1 {
    font-weight   : 700;
    font-size     : 5.5rem;
    line-height   : 6.0rem;
    letter-spacing: -0.01em;
    text-transform: capitalize;
    margin        : 1.7rem 0 3.3rem;
}

.story_slide p {
    font-family   : 'Raleway', sans-serif;
    font-size     : 2.0rem;
    line-height   : 3.7rem;
    letter-spacing: 0.03em;
    text-transform: capitalize;
    color         : var(--black-light);
}

@media (max-width: 1199.98px) {
    .story_slider .swiper-pagination {
        bottom: -.5rem;
    }
    .story_slide h1 {
        font-size: 4.2rem;
        line-height: 4.8rem;
        margin: 1.2rem 0 2rem;
    }
    .story_slide p {
        font-size: 1.8rem;
        line-height: 2.8rem;
    }
}

@media (orientation: portrait) {
    .story_slider_section {
        padding: 10rem 0 12rem;
    }
    .story_slider .swiper-pagination {
        left  : 50%;
        transform: translateX(-50%);
    }
    .story_slide {
        display       : flex;
        flex-direction: column;
    }

    .story_slide .col.left {
        order: 1;
        margin-bottom: 3.2rem;
    }

    .story_slide .col.right {
        order        : 0;
        margin-bottom: 4rem;
    }

    .story_slide h1 {
        font-size  : 3.5rem;
        line-height: 3.8rem;
        margin     : 1.7rem 0 2.4rem;
    }

    .story_slide p {
        font-size  : 1.4rem;
        line-height: 2.2rem;
    }
}