.banner_slider_section {
    padding-top: 15rem;
    background : #F4F4F4;
}

.banner_slider .swiper-slide {
    background: #F4F4F4;
}

.banner_slider .swiper-pagination {
    left  : 0;
    bottom: 8.8rem;
}

.banner_slide {
    display              : grid;
    grid-template-columns: 1fr 1fr;
    column-gap           : 4rem;
}

.banner_slide .col.left {
    margin-top: 3rem;
    text-align: center;
}

.banner_slide .col.right {
    text-align: right;
}

.banner_slide .content {
    width    : 100%;
    max-width: 61rem;
    margin   : 0 auto;
}

.banner_slide h1 {
    font-family   : 'brittany_signatureregular';
    font-weight   : normal;
    font-size     : 11rem;
    line-height   : 18.5rem;
    letter-spacing: -0.01em;
    text-transform: capitalize;
}

.banner_slide h2 {
    font-family   : 'bebas_neue_proregular';
    font-size     : 7rem;
    line-height   : 6.8rem;
    font-weight   : normal;
    text-transform: uppercase;
    color         : var(--white);
    background    : var(--black);
    padding       : .2rem 4.2rem;
}

.banner_slide h1 span {
    color: var(--gray);
}

.banner_slide img {
    height: 57rem;
}

@media (max-width: 1199.98px) {
    .banner_slide .content {
        max-width: 34rem;
    }
    .banner_slide h1 {
        font-size    : 5rem;
        line-height  : 10.4rem;
        margin-bottom: 1rem;
    }

    .banner_slide h2 {
        font-size  : 4rem;
        line-height: 4.8rem;
    }

    .banner_slide img {
        height: 35rem;
    }

}

@media (orientation: portrait) {
    .banner_slider_section {
        padding-top: 0;
        background : var(--white);
    }

    .banner_slider_section .container {
        padding: 0;
    }

    .banner_slider_section .swiper {
        padding-bottom: 3.2rem;
    }

    .banner_slide {
        display: block;
    }

    .banner_slider .swiper-pagination {
        left     : 50%;
        bottom   : -0.5rem;
        transform: translateX(-50%);
    }

    .banner_slide .col.left {
        margin-top: 0;
        margin    : 3.2rem 0 3rem;
    }

    .banner_slide .col.right {
        text-align: center;
    }

    .banner_slide .content {
        max-width: 34rem;
    }

    .banner_slide h1 {
        font-size    : 5rem;
        line-height  : 10.4rem;
        margin-bottom: 1rem;
    }

    .banner_slide h2 {
        font-size  : 4rem;
        line-height: 4.8rem;
    }

    .banner_slide img {
        height: 31rem;
    }
}