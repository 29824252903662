/* .swiper-slide {
    visibility: hidden;
    transition-property: opacity, visibility !important;
}

.swiper-slide-active {
    visibility: visible;
} */


.swiper-pagination {
    position: absolute;
    z-index : 1;
}

.swiper-pagination-bullet {
    width        : 2rem !important;
    height       : .6rem !important;
    background   : #D5D5D5;
    opacity      : 1 !important;
    border-radius: .8rem;
    cursor       : pointer;
    margin       : 0 .5rem !important;
}

.swiper-pagination-bullet-active {
    background: var(--black);
}