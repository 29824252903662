
@font-face {
    font-family: 'D-DIN';
    src: url('./D-DIN.woff2') format('woff2'),
        url('./D-DIN.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'D-DIN';
    src: url('./D-DIN-Bold.woff2') format('woff2'),
        url('./D-DIN-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}



@font-face {
    font-family: 'D-DIN';
    src: url('./D-DIN-Italic.woff2') format('woff2'),
        url('./D-DIN-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}


/* brittany fonts */
@font-face {
    font-family: 'brittany_signatureregular';
    src: url('./brittanysignature-webfont.woff2') format('woff2'),
         url('./brittanysignature-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}



/* bebas neue pro */
@font-face {
    font-family: 'bebas_neue_proregular';
    src: url('./bebasneuepro-regular-webfont.woff2') format('woff2'),
         url('./bebasneuepro-regular-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;
}
