.footer {
  background: var(--black);
  padding   : 2.4rem 4rem;
  position: relative;
}

.footer .row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.footer h3 {
  font-weight: 600;
  font-size  : 1.6rem;
  line-height: 1.8rem;
  color      : var(--white);
}

.footer h3 a {
  color   : var(--white);
  border-bottom: 1px solid var(--white);
}
.footer .social_wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


@media (orientation: portrait) {
  .footer {
    padding   : 3.2rem 2.4rem;
  }
  .footer .row {
    display: block;
    text-align: center;
  }
  .footer .row>*:nth-child(1) {
    margin-bottom: 8rem;
  }
  .footer h3 {
    font-weight: 400;
}
}
