* {
    margin    : 0;
    padding   : 0;
    box-sizing: border-box;
  }
  
  li {
    list-style: none;
  }
  
  a {
    text-decoration: none;
  }
  
  button {
    border          : 0;
    background-color: transparent;
    cursor          : pointer;
  }
  
  img, svg {
    vertical-align: middle;
  }
  span {
      display: inline-block;
  }